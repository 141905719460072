import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ["options", "gradient"],
  mounted() {
    //  	if (this.gradient) {
    // 	console.log(this.$refs)
    // 	let ctx = this.$refs.canvas.getContext('2d')
    // 	let gradient=ctx.createLinearGradient(0, 0, 0, 450)

    //     gradient.addColorStop(0, 'rgba(255, 0,0, 0.5)')
    //     gradient.addColorStop(0.5, 'rgba(255, 0, 0, 0.25)');
    //     gradient.addColorStop(1, 'rgba(255, 0, 0, 0)');

    //  	}
    //    console.log(ctx.style)

    // this.chartData.datasets.forEach((d, i) => {
    // 	if (this.borderColor == 'GRADIENT') {

    // 	}
    // })

    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
  },
};
