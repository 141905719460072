import { area } from "@turf/turf";
import nbsCalculator from "./nbs";
import { charts } from "./charts";
import { dataForYear, target } from "./target";
import calculate from "./calculate";
import satisfaction from "./satisfaction";

export default function (
  solution,
  objectTypes,
  indicatorTypes = null,
  nbsCategories = null
) {
  let years = [];
  let indicators = {};
  indicatorTypes.forEach((i) => {
    if (solution.scenario.indicators[i._id]) {
      indicators[i._id] = Object.assign(
        {},
        i,
        solution.scenario.indicators[i._id]
      );
    } else {
      indicators[i._id] = i;
    }
  });

  Object.values(indicators).forEach((i) => {
    if (i.baseline) years = years.concat(Object.keys(i.baseline));
    if (i.targets) years = years.concat(Object.keys(i.targets));
  });
  years = Array.from(
    new Set(
      years.concat(solution.scenario.startYear || new Date().getFullYear())
    )
  ).sort();

  let object = {
    solution: solution,
    objectTypes: objectTypes,
    indicators: indicators,
    nbsCategories: nbsCategories,
    year: solution.timeline,
    years: years,

    satisfaction(indicator) {
      return satisfaction(this.deployedNbs(), this.objectTypes, indicator);
    },

    // An overall 1-100 score reflecting whether indicator and satisfaction targets has been met.
    overallScore() {
      let sum = 0;

      let keyIndicators =
        this.solution.scenario.keyIndicators &&
        this.solution.scenario.keyIndicators.length > 1
          ? this.solution.scenario.keyIndicators
          : Object.values(this.indicators)
              .filter((i) => {
                return !i.economicScore;
              })
              .map((i) => {
                return i._id;
              });

      keyIndicators.forEach((i) => {
        let val = (this.targetAbs(i) - this.targetDiff(i)) / this.targetAbs(i);
        sum += val;
      });

      let indicatorsResult = (sum / keyIndicators.length) * 0.5;
      let satisfactionResult = (this.satisfaction() / 5) * 0.5;

      if (indicatorsResult > 0.5) indicatorsResult = 0.5;
      if (satisfactionResult > 0.5) satisfactionResult = 0.5;

      let result = parseInt((indicatorsResult + satisfactionResult) * 100);

      return result > 100 ? 100 : result;
    },

    // Returns evaluation of the currently deployed NBS for the given indicator
    evaluation(indicator, year = null) {
      if (!year) {
        year = this.year;
      }

      let method = "absolute";

      const deployedNbs = this.deployedNbs();
      const baseline = dataForYear(year, this.indicators[indicator].baseline);
      const totalArea = Math.floor(
        area(this.solution.scenario.site.boundaries)
      );

      if (
        this.indicators[indicator].calculationMethod === "average" ||
        this.indicators[indicator].type === "average"
      ) {
        method = "average";
      } else if (
        this.indicators[indicator].calculationMethod === "percentage" ||
        this.indicators[indicator].calculationMethod === "percentageOfArea"
      ) {
        method = "percentage";
      }
      let result = calculate[method](
        indicator,
        baseline,
        deployedNbs,
        totalArea,
        this.objectTypes
      );

      let threshold = this.indicators[indicator].threshold;
      if (threshold) {
        switch (this.indicators[indicator].targetDirection) {
          case "le":
            if (result < threshold) result = threshold;
            break;
          case "ge":
            if (result > threshold) result = threshold;
            break;
        }
      }
      return result;
    },

    // additionalImpacts(indicator) {
    // 	if (!this.nbsCategories) {
    // 		return false
    // 	}
    // 	let deployedNbs = this.deployedNbs()
    // 	let totalArea = Math.floor(area(this.solution.scenario.site.boundaries))

    // 	let types = {}
    // 	for (let type in this.objectTypes) {
    // 		if (this.objectTypes.hasOwnProperty(type)) {
    // 			let category = this.objectTypes[type].category
    // 			if (!category) continue;
    // 			types[category] = types[category] || 0
    // 			if (deployedNbs[type]) types[category] += deployedNbs[type].size
    // 		}
    // 	}

    // 	let score = 0

    // 	for (let category in types) {
    // 		if (types.hasOwnProperty(category)) {
    // 			let _score = this.nbsCategories[category].impacts[indicator] * types[category] / totalArea * 100
    // 			if (!isNaN(_score)) score += _score
    // 		}
    // 	}
    // 	let result = parseInt(score)
    // 	return (result > 100) ? 100 : result
    // },

    // Maximum value of the indicator, either the target for normal indicators or 5 for satisfaction values.
    max(indicator) {
      return this.indicators[indicator] ? this.targetAbs(indicator) : 5;
    },

    // Result value as a percentage to target value
    resultPercentage(indicator) {
      let result = this.indicators[indicator]
        ? this.targetAbs(indicator) - this.targetDiff(indicator)
        : this.satisfaction(indicator);
      return ((result / (this.max(indicator) || 1)) * 100).toFixed(1);
    },

    // Difference of result from baseline value
    targetDiffResult(indicator) {
      let baseline = dataForYear(
        this.solution.scenario.startYear,
        this.indicators[indicator].baseline
      );
      let result = this.evaluation(indicator);
      switch (this.targetDirection(indicator)) {
        case "le":
          return parseFloat((baseline - result).toFixed(1));
        case "ge":
          return parseFloat((result - baseline).toFixed(1));
      }
    },

    // Difference of result from target.
    targetDiff(indicator) {
      let result = this.evaluation(
        indicator,
        this.years[this.years.length - 1]
      );
      let target = this.target(
        indicator,
        this.years[this.years.length - 1],
        true
      );
      switch (this.targetDirection(indicator)) {
        case "le":
          return result - target;
        case "ge":
          return target - result;
      }
    },

    // Boolean, true if the target for the indicator has been reached.
    targetReached(indicator) {
      let indicatorTarget = this.indicators[indicator];
      if (!indicatorTarget) {
        return null;
      }
      switch (this.targetDirection(indicator)) {
        case "le":
          return this.evaluation(indicator) <= this.target(indicator);
        case "ge":
          return this.evaluation(indicator) >= this.target(indicator);
      }
    },

    generateText() {
      let nbsText = [
        `<p>Your overall result score is <b>${this.overallScore()}</b> out of 100. </p>\n<p>By deploying `,
      ];

      let nbs = this.deployedNbs();

      let totalCost = 0;
      let maintenance = 0;

      if (Object.values(nbs).length == 0) {
        nbsText.push("nothing");
      }

      let i = 1;
      for (let type in nbs) {
        if (
          nbs.hasOwnProperty(type) &&
          nbs[type].size > 0 &&
          !this.objectTypes[type].amenity
        ) {
          if (i > 1) {
            let sep = i == Object.values(nbs).length ? " and " : ", ";
            nbsText.push(sep);
          }
          if (nbs[type].cost) totalCost += nbs[type].cost;
          if (nbs[type].maintenance) maintenance += nbs[type].maintenance;

          nbsText.push(
            `<b>${nbs[type].size} ${nbs[type].unit}</b> of ${this.objectTypes[
              type
            ].name.toLowerCase()}`
          );
          i++;
        }
      }
      nbsText.push(", you are able to ");
      let verbs = { ge: "increase", le: "decrease" };
      let keyIndicators =
        this.solution.scenario.keyIndicators &&
        this.solution.scenario.keyIndicators.length > 1
          ? this.solution.scenario.keyIndicators
          : Object.values(this.indicators)
              .filter((i) => {
                return !i.economicScore;
              })
              .map((i) => {
                return i._id;
              });

      keyIndicators.forEach((indicator_id, i) => {
        let indicator = this.indicators[indicator_id];
        if (i > 0) nbsText.push(" and ");
        let unit = indicator.unit ? ` ${indicator.unit}` : "";
        nbsText.push(`${verbs[this.targetDirection(indicator_id)]} \
${indicator.name.toLowerCase()} from <b>${dataForYear(
          this.years[this.years.length - 1],
          indicator.baseline
        )}</b> \
to <b>${this.evaluation(indicator_id)}${unit}</b>`);
      });

      nbsText.push(".</p>\n");

      nbsText.push(`<p>As a result of changing the course of the key indicators and placing amenities and \
facilities in the planning site, you will also reach an average <b>${this.satisfaction()}</b> out \
of 5 point stakeholder satisfaction by ${
        this.years[this.years.length - 1]
      }.</p>\n`);

      nbsText.push(
        `<p>The implemented solutions cost <b>${totalCost.toFixed(
          2
        )} €</b> and have an annual maintenance cost of <b>${maintenance.toFixed(
          2
        )} €</b>.</p>`
      );

      return nbsText.join("");
    },
  };
  return Object.assign(object, nbsCalculator, charts, target);
}
