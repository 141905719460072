import { dataForYear, target } from "./target";

export const charts = Object.assign(target, {
  getChartData() {
    if (!this.chartData) {
      this.chartData = {
        type: "line",
        labels: this.years,
        datasets: [],
      };
    }
    return JSON.parse(JSON.stringify(this.chartData));
  },

  baselineDatacollection(indicator, data = null) {
    if (!data) data = this.getChartData(indicator);
    data.datasets = data.datasets.concat([
      {
        label: "Baseline",
        borderColor: "#33AC5F",
        borderWidth: "1",
        fill: false,
        data: [],
      },
      {
        label: "Business As Usual",
        borderColor: "#EB621C",
        borderWidth: "1",
        fill: false,
        borderDash: [5, 5],
        data: [],
      },
    ]);

    data.labels.forEach((year) => {
      if (year < this.year) {
        data.datasets[0].data.push(
          dataForYear(year, this.indicators[indicator].baseline)
        );
        data.datasets[1].data.push(null);
      } else if (year == this.year) {
        data.datasets[0].data.push(
          dataForYear(year, this.indicators[indicator].baseline)
        );
        data.datasets[1].data.push(
          dataForYear(year, this.indicators[indicator].baseline)
        );
      } else {
        data.datasets[0].data.push(null);
        data.datasets[1].data.push(
          dataForYear(year, this.indicators[indicator].baseline)
        );
      }
    });
    return data;
  },

  baselineWithTargetDatacollection(indicator, data = null, flat = true) {
    if (!data) data = this.baselineDatacollection(indicator);

    data.datasets = data.datasets.concat([
      {
        label: "Target",
        borderColor: "#008000",
        borderWidth: 1,
        pointBackgroundColor: "#00CC00",
        data: [],
        borderDash: [5, 5],
        pointStyle: "rectRot",
        pointRadius: 5,
      },
    ]);

    if (!flat) {
      data.datasets[data.datasets.length - 1].pointRadius = 0;
    }

    const i = data.datasets.length - 1;

    data.labels.forEach((year) => {
      if (!flat) {
        if (year < this.year) {
          data.datasets[i].data.push(null);
        } else if (year == this.year) {
          data.datasets[i].data.push(
            dataForYear(year, this.indicators[indicator].baseline)
          );
        } else {
          data.datasets[i].data.push(this.target(indicator, year, flat));
        }
      } else {
        if (
          this.indicators[indicator].targets &&
          this.indicators[indicator].targets.hasOwnProperty(year.toString())
        ) {
          data.datasets[i].data.push(this.target(indicator, year));
        } else {
          data.datasets[i].data.push(null);
        }
      }
    });

    return data;
  },

  // Helper to generate data collection for a chart
  datacollection(indicator, data = null) {
    // Add target data flat line
    data = this.baselineWithTargetDatacollection(indicator, data);

    data.datasets = data.datasets.concat([
      {
        label: "Your solution",
        borderColor: "#0000aa",
        borderWidth: 1,
        fill: false,
        data: [],
      },
    ]);

    data.labels.forEach((year) => {
      const i = data.datasets.length - 1;
      if (year < this.solution.scenario.startYear) {
        data.datasets[i].data.push(null);
      } else if (parseInt(year) == this.solution.scenario.startYear) {
        data.datasets[i].data.push(
          dataForYear(year, this.indicators[indicator].baseline)
        );
      } else {
        data.datasets[i].data.push(this.evaluation(indicator, year));
      }
    });
    return data;
  },
  // Helper for generating the chart options
  chartOptions(indicator, axis = false, legend = false) {
    const minYear = this.years[0];
    const maxYear = this.years[this.years.length - 1];

    const max = Math.max.apply(
      null,
      Object.values(this.indicators[indicator].baseline || {}).concat(
        Object.values(this.indicators[indicator].targets || {}),
        [
          this.evaluation(indicator, minYear),
          this.evaluation(indicator, maxYear),
        ]
      )
    );

    const min = Math.min.apply(
      null,
      Object.values(this.indicators[indicator].baseline || {}).concat(
        Object.values(this.indicators[indicator].targets || {}),
        [
          this.evaluation(indicator, minYear),
          this.evaluation(indicator, maxYear),
        ]
      )
    );
    const axisLabel = axis
      ? this.indicators[indicator].axisUnit ||
        this.indicators[indicator].unit ||
        ""
      : "";

    return {
      tooltip: {
        enabled: true,
      },
      maintainAspectRatio: false,
      responsive: false,
      legend: {
        display: legend,
        position: "chartArea",
        labels: {
          filter: (options, data) => {
            return data.datasets.find((d) => {
              return (
                d.label == options.text &&
                d.data.filter((val) => {
                  return val !== null;
                }).length > 0
              );
            });
          },
        },
      },
      elements: {
        point: { radius: 1 },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              max: this.years[this.years.length - 1],
            },

            // display: false,
            scaleLabel: {
              display: true,
            },
            gridLines: {
              display: false,
              zeroLineColor: "transparent",
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              suggestedMin: min,
              beginAtZero: false,
              max: max,
              autoSkipPadding: 50,
              autoSkip: true,
              maxTicksLimit: 4,
            },
            scaleLabel: {
              display: true,
              labelString: axisLabel,
            },
            gridLines: {
              display: false,
              zeroLineColor: "transparent",
            },
          },
        ],
      },
      style: {
        "border-radius": "10px",
      },
    };
  },
});
