export const dataForYear = function (year, data, avg = true) {
  const baseline = [];
  if (data === undefined) {
    return null;
  }
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      baseline.push([key, data[key]]);
    }
  }
  baseline.sort(function (a, b) {
    if (a[0] > b[0]) {
      return 1;
    } else {
      return -1;
    }
  });
  let lastval = 0;
  let value = null;

  for (let i = 0; i < baseline.length; i++) {
    const b = baseline[i];
    const val = parseFloat(b[1]);

    if (parseInt(b[0]) === parseInt(year)) {
      value = val;
      break;
    } else if (parseInt(b[0]) > parseInt(year)) {
      if (avg) {
        value = (lastval + val) / 2;
      } else {
        value = val;
      }
      break;
    } else {
      value = val;
    }
    lastval = val;
  }
  return value;
};

export const target = {
  // Get target value for the given scenario.
  target(indicator, year = null, flat) {
    if (!year) year = this.year;
    if (
      this.indicators[indicator].targets &&
      this.indicators[indicator].targets != {}
    ) {
      let targetData = {};
      // if (!flat) {
      // 	targetData[this.solution.scenario.startYear] = dataForYear(this.solution.scenario.startYear, this.indicators[indicator].baseline)
      // }
      targetData = Object.assign(
        {},
        this.indicators[indicator].targets,
        targetData
      );
      return dataForYear(year, targetData, !flat);
    } else {
      return null;
    }
  },

  targetDirection(indicator) {
    const targetYear = this.years[this.years.length - 1];

    const baseline = dataForYear(
      targetYear,
      this.indicators[indicator].baseline
    );
    const targetValue = this.target(indicator, targetYear);

    if (parseFloat(targetValue) < parseFloat(baseline)) {
      return "le";
    } else if (parseFloat(targetValue) > parseFloat(baseline)) {
      return "ge";
    }
  },

  targetAbs(indicator) {
    const targetValue = dataForYear(
      this.years[this.years.length - 1],
      this.indicators[indicator].targets
    );
    const baseline = dataForYear(
      this.years[this.years.length - 1],
      this.indicators[indicator].baseline
    );

    switch (this.targetDirection(indicator)) {
      case "le":
        return baseline - targetValue;
      case "ge":
        return targetValue - baseline;
    }
  },
};
