export default function (deployedNbs, objectTypes, indicator = null) {
  let nbsCount = 0;
  for (let type in deployedNbs) {
    if (deployedNbs.hasOwnProperty(type) && objectTypes[type]) {
      if (objectTypes[type].satisfaction) {
        nbsCount += 1;
      }
    }
  }
  let countRatio = nbsCount >= 5 ? 1 : nbsCount * 0.2;

  let arrAvg = (arr) => {
    let res = arr.reduce((a, b) => a + b, 0);
    return res / arr.length;
  };

  let satisfaction = {
    young: [],
    adult: [],
    old: [],
  };

  for (let type in deployedNbs) {
    if (objectTypes[type] && objectTypes[type].satisfaction) {
      for (let ageGroup in objectTypes[type].satisfaction) {
        if (objectTypes[type].satisfaction.hasOwnProperty(ageGroup)) {
          satisfaction[ageGroup].push(
            parseInt(objectTypes[type].satisfaction[ageGroup])
          );
        }
      }
    }
  }

  for (let ageGroup in satisfaction) {
    if (satisfaction.hasOwnProperty(ageGroup)) {
      satisfaction[ageGroup] = satisfaction[ageGroup].length
        ? arrAvg(satisfaction[ageGroup])
        : 0;
    }
  }

  if (!indicator) {
    let value = arrAvg(Object.values(satisfaction)) * countRatio;
    return parseInt(value) === parseFloat(value)
      ? value
      : parseFloat(value.toFixed(1));
  } else {
    let value = satisfaction[indicator] * countRatio;
    return parseInt(value) === parseFloat(value)
      ? value
      : parseFloat(value.toFixed(1));
  }
}
